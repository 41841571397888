<template>
  <div>
    <AppMenu />
       <main class="lg:relative">
      <div
        class="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left"
      >
        <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1
            class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-3xl xl:text-5xl"
          >
            <span class="block xl:inline">Centro CERCA e ri</span>
            <span class="block text-blue-900 xl:inline">CERCA</span>
          </h1>
          <p
            class="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"
          >
            Video dei nostri pazienti ...
          </p>
        </div>
      </div>
      <div
        class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full"
      >
        <img
          class="absolute inset-0 object-cover w-full h-full"
          src="@/assets/hospital.jpeg"
          alt=""
        />
      </div>
    </main>
    <PatientComponent
      subject="Andrea Cappelletti"
      patology="Reflusso Gastroesofageo"
      title="Titolo"
      description="My description" 
      :youtubeUrl=localYoutubeUrl
      />
        <CtaContactSection />
    <FooterSection />
    <slot />
  </div>

</template>

<script>
import AppMenu from "../components/AppMenu";
import PatientComponent from '../components/PatientComponent.vue';
import CtaContactSection from "../components/CtaContactSection.vue";
// import Banner from "../components/Banner.vue";
import FooterSection from "../components/FooterSection.vue";
export default {
  name: "Patients",
    data() {
    return {
      localYoutubeUrl: "https://www.youtube.com/embed/2aHkqB2-46k",
    };
  },
  components: {
    AppMenu,
    PatientComponent,
    CtaContactSection,
    FooterSection
  },
  
};
</script>

